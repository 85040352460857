class DoseSubstance {
    constructor() {
        this.form = document.getElementById("jsCalcDoseSubstance");

        if (this.form) {
            this.quantity = document.querySelector(".jsDoseSubstanceQuantity");
            this.quantityType = document.querySelector(".jsDoseSubstanceQuantityUnit");
            this.quantityTypeValue = this.quantityType.value;

            this.volume = document.querySelector(".jsDoseSubstanceVolume");
            this.volumeType = document.querySelector(".jsDoseSubstanceVolumeUnit");
            this.volumeTypeValue = this.volumeType.value;

            this.quantity2 = document.querySelector(".jsDoseSubstanceQuantity2");
            this.quantity2Type = document.querySelector(".jsDoseSubstanceQuantity2Unit");
            this.quantity2TypeValue = this.quantity2Type.value;

            this.results = document.querySelector(".jsDoseSubstanceResult");

            this.setTypeValue();
            this.events();
        }
    }

    events() {
        let self = this;
        this.quantityType.addEventListener("change", function () {
            self.setTypeValue();
            self.calculate();
        });

        this.volumeType.addEventListener("change", function () {
            self.setTypeValue();
            self.calculate();
        });

        this.quantity2Type.addEventListener("change", function () {
            self.setTypeValue();
            self.calculate();
        });

        this.quantity.addEventListener("input", function () {
            self.calculate();
        });

        this.quantity2.addEventListener("input", function () {
            self.calculate();
        });

        this.volume.addEventListener("input", function () {
            self.calculate();
        });
    }

    setTypeValue() {
        this.quantityTypeValue = parseFloat(this.quantityType.value).toFixed(2);
        this.volumeTypeValue = parseFloat(this.volumeType.value).toFixed(2);
        this.quantity2TypeValue = parseFloat(this.quantity2Type.value).toFixed(2);
    }

    calculate() {
        let quantity = 0;
        let volume = 0;
        let quantity2 = 0;
        let result = 0;

        if (this.validation()) {
            quantity = this.quantity.value * this.quantityTypeValue;
            volume = this.volume.value * this.volumeTypeValue;
            quantity2 = this.quantity2.value * this.quantity2TypeValue;
            result = (quantity2) / (quantity / volume);
            this.results.innerHTML = result.toFixed(2);
        }
    }

    validation() {
        let validate = false;
        let resultsRow = document.querySelector(".form__row--results");
        resultsRow.style.display = "none";

        if (this.quantity.value.length > 0 && this.quantity2.value.length > 0 && this.volume.value.length > 0) {
            validate = true;
            resultsRow.style.display = "flex";
        }

        return validate;
    }
}

export default DoseSubstance;