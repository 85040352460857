class Nystatin {
    constructor() {
        this.form = document.getElementById("jsCalcNystatin");

        if (this.form) {
            this.formQuantity = document.querySelector(".jsNystatinQuantity");
            this.formActivity = document.querySelector(".jsNystatinActivity");
            this.results = document.querySelector(".jsNystatinResults");

            this.events();
        }
    }

    events() {
        let self = this;

        this.formQuantity.addEventListener("input", function () {
            self.calculate();
        });

        this.formActivity.addEventListener("input", function () {
            self.calculate();
        });
    }

    calculate() {
        let calculation = 0;

        if (this.validation()) {
            calculation = this.formQuantity.value / this.formActivity.value;
            calculation = calculation.toFixed(2);
            this.results.innerHTML = `Do wykonania leku potrzebujesz ${calculation} mg nystatyny.`;
        }
    }

    validation() {
        let validate = false;
        let resultsRow = document.querySelector(".form__row--results");
        resultsRow.style.display = "none";

        if(this.formActivity.value.length > 0 && this.formQuantity.value.length > 0) {
            validate = true;
            resultsRow.style.display = "flex";
        }

        return validate;
    }
}

export default Nystatin;