class HomeMeasurements {
    constructor() {
        this.form = document.getElementById("jsCalcHomeMea");

        if (this.form) {
            this.type = document.querySelector(".jsHomeMeaType");
            this.typeValue = this.type.value;
            this.type2 = document.querySelector(".jsHomeMeaType2");
            this.type2Value = this.type.value;
            this.unit = document.querySelector(".jsHomeMeaUnit");
            this.unit2 = document.querySelector(".jsHomeMeaUnit2");
            this.option = document.querySelector(".jsHomeMeaOption");
            this.weight = document.querySelector(".jsHomeMeaWeight");
            this.weight2 = document.querySelector(".jsHomeMeaWeight2");
            this.results = document.querySelector(".jsHomeMeaResults");
            this.weightRow = document.querySelector(".jsHomeMeaWeightRow");
            this.weightRow.style.display = "none";


            this.events();
        }
    }

    events() {
        let self = this;
        this.type.addEventListener("change", function () {
            self.setTypeValue();
            self.calculate();
        });

        this.type2.addEventListener("change", function () {
            self.setTypeValue();
            self.calculate();
        });

        this.unit.addEventListener("input", function () {
            self.calculate();
        });

        this.unit2.addEventListener("input", function () {
            self.calculate();
        });

        this.weight.addEventListener("input", function () {
            self.calculate();
        });

        this.weight2.addEventListener("input", function () {
            self.calculate();
        });

        this.option.addEventListener("change", function () {
            if(self.option.checked) {
                self.weightRow.style.display = "block";
            } else {
                self.weightRow.style.display = "none";
            }
            self.calculate();
        });
    }

    setTypeValue() {
        this.typeValue = this.type.value;
        this.type2Value = this.type2.value;
    }

    calculate() {
        let calculation1 = 0;
        let calculation2 = 0;
        let calculation3 = 0;
        let calculation4 = 0;

        if (this.validation()) {
            if (this.option.checked) {
                calculation1 = this.typeValue * this.type2Value * this.unit.value;
                calculation2 = calculation1 * this.unit2.value;
                calculation3 = (calculation1 * this.weight2.value)/this.weight.value;
                calculation4 = calculation3 * this.unit2.value;
                this.results.innerHTML = `Pacjent przyjmie jednorazowo <strong>${calculation1} g</strong> preparatu, a dobowo <strong>${calculation2} g</strong>  preparatu. Dawka jednorazowa substacji czynnej wynosi <strong>${calculation3} g</strong>, a dobowa <strong>${calculation4} g</strong>.`;
            } else {
                calculation1 = this.typeValue * this.type2Value * this.unit.value;
                calculation2 = calculation1 * this.unit2.value;
                this.results.innerHTML = `Pacjent przyjmie jednorazowo <strong>${calculation1} g</strong> preparatu, a dobowo <strong>${calculation2} g</strong> preparatu.`;
            }
        }
    }

    validation() {
        let validate = false;
        let resultsRow = document.querySelector(".form__row--results");
        resultsRow.style.display = "none";

        if (this.option.checked) {
            if ((this.unit && this.unit.value > 0) && (this.unit2 && this.unit2.value > 0) && this.typeValue > 0 && this.type2Value > 0 && this.weight.value > 0 && this.weight2.value > 0) {
                resultsRow.style.display = "block";
                validate = true;
            }
        } else {
            if ((this.unit && this.unit.value > 0) && (this.unit2 && this.unit2.value > 0) && this.typeValue > 0 && this.type2Value > 0) {
                resultsRow.style.display = "block";
                validate = true;
            }
        }

        return validate;
    }
}

export default HomeMeasurements;