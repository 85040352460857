class Ethanol {
    constructor() {
        this.form = document.getElementById("jsCalcEthanol");

        if (this.form) {
            this.type = document.querySelector(".jsEthConc");
            this.typeValue = this.type.value;
            this.sol = document.querySelector(".jsEthSol");
            this.typeRow = document.querySelector(".jsEthConcRow");
            // this.typeRow.style.display = "none";
            this.type90 = document.querySelector(".jsEthConc90");
            this.type90Value = this.type90.value;
            this.type90.style.display = "none";
            this.type70 = document.querySelector(".jsEthConc70");
            this.type70Value = this.type70.value;
            this.type70.style.display = "none";
            this.sol90 = document.querySelector(".jsEthSol90");
            this.sol90Value = this.sol90.value;
            this.sol90.style.display = "none";
            this.sol70 = document.querySelector(".jsEthSol70");
            this.sol70Value = this.sol70.value;
            this.sol70.style.display = "none";
            this.results = document.querySelector(".jsEthResults");
            this.results2 = document.querySelector(".jsEthResults2");

            this.setTypeValue();
            this.events();
        }
    }

    events() {
        let self = this;
        this.type.addEventListener("change", function () {
            self.setTypeValue();
            self.calculate();
        });

        this.type90.addEventListener("change", function () {
            self.setTypeValue();
            self.calculate();
        });

        this.type70.addEventListener("change", function () {
            self.setTypeValue();
            self.calculate();
        });

        this.sol90.addEventListener("change", function () {
            self.setTypeValue();
            self.calculate();
        });

        this.sol70.addEventListener("change", function () {
            self.setTypeValue();
            self.calculate();
        });

        this.sol.addEventListener("input", function () {
            self.setTypeValue();
            self.calculate();
        });
    }

    setTypeValue() {
        this.typeValue = parseFloat(this.type.value).toFixed(2);
        this.type90Value = parseFloat(this.type90.value).toFixed(2);
        this.type70Value = parseFloat(this.type70.value).toFixed(2);
        this.sol90Value = parseFloat(this.sol90.value).toFixed(2);
        this.sol70Value = parseFloat(this.sol70.value).toFixed(2);

        if (this.typeValue == 96) {
            this.typeRow.style.display = "flex";
            this.type90.style.display = "block";
            this.sol90.style.display = "block";
            this.type70.style.display = "none";
            this.sol70.style.display = "none";
        } else if (this.typeValue == 70) {
            this.typeRow.style.display = "flex";
            this.type70.style.display = "block";
            this.sol70.style.display = "block";
            this.type90.style.display = "none";
            this.sol90.style.display = "none";
        } else {
            this.typeRow.style.display = "none";
            this.type70.style.display = "none";
            this.sol70.style.display = "none";
            this.type90.style.display = "none";
            this.sol90.style.display = "none";
        }
    }

    calculate() {
        let calculation = 0;
        let calculation2 = 0;

        if (this.validation()) {
            if(this.typeValue == 96) {
                calculation = (((this.sol90Value / this.type90Value) * this.sol.value) * 100) / 100;
            } else if (this.typeValue == 70) {
                calculation = (((this.sol70Value / this.type70Value) * this.sol.value) * 100) / 100;
            }
            calculation2 = (this.sol.value - calculation) * 100 / 100;
            this.results.value = calculation.toFixed(2);
            this.results2.value = calculation2.toFixed(2);
        }
    }

    validation() {
        let validate = false;
        let resultsRow = document.querySelector(".form__row--results");
        resultsRow.style.display = "none";

        if (this.typeValue > 0 && this.sol.value.length > 0) {
            validate = true;
        }

        if (validate) {
            resultsRow.style.display = "block";
        }

        return validate;
    }
}

export default Ethanol;