class HandDisinfection {
    constructor() {
        this.form = document.getElementById("jsCalcDisinfection");

        if (this.form) {
            this.formUnit = document.querySelector(".jsDisinfectionUnit");
            this.formUnitValue = this.formUnit.value;
            this.formUnit2 = document.querySelector(".jsDisinfectionUnit2");
            this.formUnit2Value = this.formUnit2.value;
            this.formValue = document.querySelector(".jsDisinfectionValue");

            this.events();
        }
    }

    events() {
        let self = this;

        this.formUnit.addEventListener("change", function () {
            self.setTypeValue();
            self.calculate();
        });

        this.formUnit2.addEventListener("change", function () {
            self.setTypeValue();
            self.calculate();
        });

        this.formValue.addEventListener("input", function () {
            self.calculate();
        });
    }

    setTypeValue() {
        this.formUnitValue = this.formUnit.value;
        this.formUnit2Value = this.formUnit2.value;
    }

    calculate() {
        let calculationGlycerolum = 0;
        let calculationHydrogenii = 0;
        let calculationEthanolum = 0;
        let calculationAqua = 0;

        if (this.validation()) {
            if (this.formUnitValue == 'g') {
                if (this.formUnit2Value == 'g') {
                    calculationGlycerolum = (21.042 * this.formValue.value) / 846.015;
                    calculationHydrogenii = (41.7 * this.formValue.value) / 846.015;
                    calculationEthanolum = (674.015 * this.formValue.value) / 846.015;
                    calculationAqua = (108.3 * this.formValue.value) / 846.015;
                } else if (this.formUnit2Value == 'ml') {
                    calculationGlycerolum = (16.7 * this.formValue.value) / 846.015;
                    calculationHydrogenii = (41.7 * this.formValue.value) / 846.015;
                    calculationEthanolum = (833.3 * this.formValue.value) / 846.015;
                    calculationAqua = (108.3 * this.formValue.value) / 846.015;
                }
            } else if (this.formUnitValue == 'ml') {
                if (this.formUnit2Value == 'g') {
                    calculationGlycerolum = (21.042 * this.formValue.value) / 1000;
                    calculationHydrogenii = (41.7 * this.formValue.value) / 1000;
                    calculationEthanolum = (674.015 * this.formValue.value) / 1000;
                    calculationAqua = (108.3 * this.formValue.value) / 1000;
                } else if (this.formUnit2Value == 'ml') {
                    calculationGlycerolum = (16.7 * this.formValue.value) / 1000;
                    calculationHydrogenii = (41.7 * this.formValue.value) / 1000;
                    calculationEthanolum = (833.3 * this.formValue.value) / 1000;
                    calculationAqua = (108.3 * this.formValue.value) / 1000;
                }
            }

            document.querySelector(".jsDisinfectionResultGlycerolum").innerHTML = calculationGlycerolum.toFixed(2);
            document.querySelector(".jsDisinfectionResultHydrogenii").innerHTML = calculationHydrogenii.toFixed(2);
            document.querySelector(".jsDisinfectionResultEthanolum").innerHTML = calculationEthanolum.toFixed(2);
            document.querySelector(".jsDisinfectionResultAqua").innerHTML = calculationAqua.toFixed(2);
            [...document.querySelectorAll(".jsDisinfectionResultUnit")]
                .map(i => i.innerHTML = this.formUnit2Value);
        }
    }

    validation() {
        let validate = false;
        let resultsRow = document.querySelector(".form__row--results");
        resultsRow.style.display = "none";

        if (this.formUnitValue.length > 0 && this.formUnit2Value.length > 0 && this.formValue.value.length > 0) {
            validate = true;
            resultsRow.style.display = "flex";
        }

        return validate;
    }
}

export default HandDisinfection;