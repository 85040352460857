import * as noUiSlider from 'nouislider';
import 'nouislider/dist/nouislider.css';

class VitaminA {
    constructor() {
        this.form = document.getElementById("jsCalcVitaminA");

        if (this.form) {
            this.vitaminProducer = document.querySelector(".jsVitaminAProducer");
            this.vitaminProducerValue = this.vitaminProducer.value;
            this.vitaminPrescription = document.querySelector(".jsVitaminAPrescription");
            this.vitaminPrescriptionValue = this.vitaminPrescription.value;
            this.rangeSlider = document.getElementById("jsCalcVitaminASlider");
            this.vitaminQuantity = document.querySelector(".jsVitaminAQuantitySubstance");
            this.vitaminNeed = document.querySelector(".jsCalcVitaminANeed");
            this.result = document.querySelector(".jsCalcVitaminAResult");

            this.initRangeSlider();
            this.setTypeValue();
            this.events();
        }
    }

    events() {
        let self = this;
        this.vitaminProducer.addEventListener("change", function () {
            self.setTypeValue();
            self.calculate();
        });

        this.vitaminPrescription.addEventListener("change", function () {
            self.setTypeValue();
            self.calculate();
        });

        this.vitaminQuantity.addEventListener("input", function () {
            self.setTypeValue();
            self.calculate();
        });

        this.rangeSlider.noUiSlider.on('update', function () {
            self.calculate();
        });
    }

    setTypeValue() {
        this.vitaminProducerValue = parseFloat(this.vitaminProducer.value).toFixed(2);
        this.vitaminPrescriptionValue = this.vitaminPrescription.value;
        this.updateRangeSlider();
    }

    updateRangeSlider() {
        let start = parseFloat(this.vitaminProducerValue).toFixed(2);
        let max = parseFloat(this.vitaminProducer.options[this.vitaminProducer.selectedIndex].dataset.max).toFixed(2);
        this.rangeSlider.noUiSlider.updateOptions({
            start: [start],
            range: {
                'min': 1.00,
                'max': parseFloat(max)
            }
        });
    }

    initRangeSlider () {
        let start = parseFloat(this.vitaminProducerValue).toFixed(2);
        let max = parseFloat(this.vitaminProducer.options[this.vitaminProducer.selectedIndex].dataset.max).toFixed(2);
        noUiSlider.create(this.rangeSlider, {
            start: [start],
            connect: true,
            step: 0.01,
            tooltips: {
                to: function(numericValue) {
                    return numericValue.toFixed(2);
                }
            },
            range: {
                'min': 1.00,
                'max': parseFloat(max)
            }
        });
    }

    calculate() {
        if (this.validation()) {
            let needResult = 0;
            let resultVitA = 0;

            if(this.vitaminPrescriptionValue == 'g') {
                resultVitA = this.vitaminQuantity.value / this.rangeSlider.noUiSlider.get() / 10;
                resultVitA = resultVitA.toFixed(2);
                this.vitaminNeed.innerHTML = `Do wykonania leku potrzebujesz: ${this.vitaminQuantity.value} g witaminy A`;
                this.result.innerHTML = `Wyceniając lek zdejmij ze stanu: ${resultVitA} opakowania witaminy A`;
            } else {
                needResult = (this.vitaminQuantity.value * 1000) / (this.vitaminProducer.options[this.vitaminProducer.selectedIndex].dataset.jm * this.rangeSlider.noUiSlider.get());
                needResult = needResult.toFixed(2);
                resultVitA = (this.vitaminQuantity.value * 1000) / this.vitaminProducer.options[this.vitaminProducer.selectedIndex].dataset.jm / 10;
                resultVitA = resultVitA.toFixed(2);
                this.vitaminNeed.innerHTML = `Do wykonania leku potrzebujesz: ${needResult} g witaminy A`;
                this.result.innerHTML = `Wyceniając lek zdejmij ze stanu: ${resultVitA} opakowania witaminy A`;
            }
        }
    }

    validation() {
        let validate = false;
        let resultsRow = document.querySelector(".jsVitaminAResults");
        resultsRow.style.display = "none";

        if (this.vitaminProducerValue > 0 && this.vitaminPrescriptionValue.length > 0 && this.vitaminQuantity.value.length > 0) {
            validate = true;
            resultsRow.style.display = "flex";
        }

        return validate;
    }
}

export default VitaminA;