class Drops {
    constructor() {
        this.form = document.getElementById("jsCalcDrops");

        if (this.form) {
            this.calc = document.querySelector(".jsDropsCalc");
            this.calcValue = this.calc.value;
            this.type = document.querySelector(".jsDropsType");
            this.typeValue = this.type.value;
            this.results = document.querySelector(".jsDropsResults");
            this.weight = document.querySelector(".jsDropsWeight");
            this.weight2 = document.querySelector(".jsDropsWeight2");
            this.weightInput = document.querySelector(".jsDropsWeightInput");
            this.weight2Input = document.querySelector(".jsDropsWeight2Input");
            this.weightRow = document.querySelector(".jsDropsWeightRow");
            this.weightRow.style.display = "none";

            this.events();
        }
    }

    events() {
        let self = this;
        this.calc.addEventListener("change", function () {
            self.setTypeValue();
            self.calculate();
        });

        this.type.addEventListener("change", function () {
            self.setTypeValue();
            self.calculate();
        });

        this.weight.addEventListener("input", function () {
            self.calculate();
        });

        this.weight2.addEventListener("input", function () {
            self.calculate();
        });
    }

    setTypeValue() {
        this.calcValue = this.calc.value;
        this.typeValue = this.type.value;

        switch (this.calcValue) {
            case "weight":
                this.weightRow.style.display = "block";
                this.weight2Input.style.display = "none";
                this.weightInput.style.display = "flex";
                break;
            case "quantity":
                this.weightRow.style.display = "block";
                this.weightInput.style.display = "none";
                this.weight2Input.style.display = "flex";
                break;
            default:
                this.weightRow.style.display = "none";
                this.weightInput.style.display = "none";
                this.weight2Input.style.display = "none";
        }
    }

    calculate() {
        let calculation1 = 0;
        let calculation2 = 0;

        if (this.validation()) {
            if (this.calcValue == 'weight') {
                calculation1 = this.weight.value * this.typeValue;
                calculation2 = calculation1 * 1000;
                this.results.innerHTML = `Ilości kropli równej <strong>${this.weight.value}</strong> odpowiada <strong>${calculation1} g (${calculation2} mg)</strong> surowca.`;
            } else {
                calculation1 = this.weight2.value / this.typeValue;
                calculation1 = calculation1.toFixed(2);
                this.results.innerHTML = `Masie surowca równej <strong>${this.weight2.value} g</strong> odpowiada <strong>${calculation1}</strong> kropli.`;
            }
        }
    }

    validation() {
        let validate = false;
        let resultsRow = document.querySelector(".form__row--results");
        resultsRow.style.display = "none";

        if (this.typeValue > 0 && this.calcValue.length > 0) {
            if (this.calcValue == 'weight' && this.weight.value.length > 0) {
                validate = true;
            } else if (this.calcValue == 'quantity' && this.weight2.value.length > 0) {
                validate = true;
            }
        }

        if (validate) {
            resultsRow.style.display = "block";
        }

        return validate;
    }
}

export default Drops;