import * as noUiSlider from 'nouislider';
import 'nouislider/dist/nouislider.css';

class VitaminE {
    constructor() {
        this.form = document.getElementById("jsCalcVitaminE");

        if (this.form) {
            this.vitaminProducer = document.querySelector(".jsVitaminEProducer");
            this.vitaminProducerValue = this.vitaminProducer.value;
            this.vitaminPrescription = document.querySelector(".jsVitaminEPrescription");
            this.vitaminPrescriptionValue = this.vitaminPrescription.value;
            this.rangeSlider = document.getElementById("jsCalcVitaminESlider");
            this.vitaminQuantity = document.querySelector(".jsVitaminEQuantitySubstance");
            this.vitaminNeed = document.querySelector(".jsCalcVitaminENeed");
            this.result = document.querySelector(".jsCalcVitaminEResult");

            this.initRangeSlider();
            this.setTypeValue();
            this.events();
        }
    }

    events() {
        let self = this;
        this.vitaminProducer.addEventListener("change", function () {
            self.setTypeValue();
            self.calculate();
        });

        this.vitaminPrescription.addEventListener("change", function () {
            self.setTypeValue();
            self.calculate();
        });

        this.vitaminQuantity.addEventListener("input", function () {
            self.setTypeValue();
            self.calculate();
        });

        this.rangeSlider.noUiSlider.on('update', function () {
            self.calculate();
        });
    }

    setTypeValue() {
        this.vitaminProducerValue = parseFloat(this.vitaminProducer.value).toFixed(2);
        this.vitaminPrescriptionValue = this.vitaminPrescription.value;
        this.updateRangeSlider();
    }

    updateRangeSlider() {
        let start = parseFloat(this.vitaminProducerValue).toFixed(2);
        let max = parseFloat(this.vitaminProducer.options[this.vitaminProducer.selectedIndex].dataset.max).toFixed(2);
        this.rangeSlider.noUiSlider.updateOptions({
            start: [start],
            range: {
                'min': 0.88,
                'max': parseFloat(max)
            }
        });
    }

    initRangeSlider () {
        let start = parseFloat(this.vitaminProducerValue).toFixed(2);
        let max = parseFloat(this.vitaminProducer.options[this.vitaminProducer.selectedIndex].dataset.max).toFixed(2);
        noUiSlider.create(this.rangeSlider, {
            start: [start],
            connect: true,
            step: 0.01,
            tooltips: {
                to: function(numericValue) {
                    return numericValue.toFixed(2);
                }
            },
            range: {
                'min': 0.88,
                'max': parseFloat(max)
            }
        });
    }

    calculate() {
        if (this.validation()) {
            let needResult = 0;
            let resultVit = 0;

            if(this.vitaminPrescriptionValue == 'e') {
                needResult = (this.vitaminQuantity.value / 0.3) * this.rangeSlider.noUiSlider.get();
                needResult = needResult.toFixed(2);
                this.vitaminNeed.innerHTML = `Do wykonania leku potrzebujesz: ${needResult} g witaminy E`;
                resultVit = this.vitaminQuantity.value / 3;
                resultVit = resultVit.toFixed(2);
                this.result.innerHTML = `Wyceniając lek zdejmij ze stanu: ${resultVit} opakowania witaminy E`;
            } else {
                this.vitaminNeed.innerHTML = `Do wykonania leku potrzebujesz: ${this.vitaminQuantity.value} g witaminy E`;
                resultVit = this.vitaminQuantity.value / this.rangeSlider.noUiSlider.get() / 10;
                resultVit = resultVit.toFixed(2);
                this.result.innerHTML = `Wyceniając lek zdejmij ze stanu: ${resultVit} opakowania witaminy E`;
            }
        }
    }

    validation() {
        let validate = false;
        let resultsRow = document.querySelector(".jsVitaminEResults");
        resultsRow.style.display = "none";

        if (this.vitaminProducerValue > 0 && this.vitaminPrescriptionValue.length > 0 && this.vitaminQuantity.value.length > 0) {
            validate = true;
            resultsRow.style.display = "flex";
        }

        return validate;
    }
}

export default VitaminE;