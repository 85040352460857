/* eslint-disable no-unused-vars */
/**
 * Import 3rd party packages
 */

/**
 * Import modules / classes
 */
import Milk from "./Modules/Milk";
import Insulin from "./Modules/Insulin";
import DoseParaIbu from "./Modules/DoseParaIbu";
import Ethanol from "./Modules/Ethanol";
import HomeMeasurements from "./Modules/HomeMeasurements";
import Drops from "./Modules/Drops";
import MaxDose from "./Modules/MaxDose";
import Nystatin from "./Modules/Nystatin";
import HandDisinfection from "./Modules/HandDisinfection";
import CocoaButter from "./Modules/CocoaButter";
import DoseSubstance from "./Modules/DoseSubstance";
import VitaminA from "./Modules/VitaminA";
import VitaminE from "./Modules/VitaminE";
import VitaminD from "./Modules/VitaminD";
import VitaminAD3 from "./Modules/VitaminAD3"


/**
 * Instantiate a new object using imported modules/classes
 */
document.addEventListener("DOMContentLoaded", () => {
   const calcMilk = new Milk();
   const calcInsulin = new Insulin();
   const doseParaIbu = new DoseParaIbu();
   const ethanol = new Ethanol();
   const homeMea = new HomeMeasurements();
   const drops = new Drops();
   const maxDose = new MaxDose();
   const nystatin = new Nystatin();
   const handDisinfection = new HandDisinfection();
   const cocoaButter = new CocoaButter();
   const doseSubstance = new DoseSubstance();
   const vitaminA = new VitaminA();
   const vitaminE = new VitaminE();
   const vitaminD = new VitaminD();
   const vitaminAD3 = new VitaminAD3();
});
