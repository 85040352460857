class MaxDose {
    constructor() {
        this.form = document.getElementById("jsCalcMaxDose");

        if (this.form) {
            this.type = document.querySelector(".jsMaxDoseType");
            this.typeValue = this.type.value;
            this.formFried = document.querySelector(".jsMaxDoseFried");
            this.formFriedAge = document.querySelector(".jsMaxDoseFriedAge");
            this.formFriedDose = document.querySelector(".jsMaxDoseFriedDose");
            this.formFried.style.display = "none";
            this.formCownling = document.querySelector(".jsMaxDoseCowling");
            this.formCownlingAge = document.querySelector(".jsMaxDoseCowlingAge");
            this.formCownlingDose = document.querySelector(".jsMaxDoseCowlingDose");
            this.formCownling.style.display = "none";
            this.formClark = document.querySelector(".jsMaxDoseClark");
            this.formClarkWeight = document.querySelector(".jsMaxDoseClarkWeight");
            this.formClarkDose = document.querySelector(".jsMaxDoseClarkDose");
            this.formClark.style.display = "none";
            this.formYoung = document.querySelector(".jsMaxDoseYoung");
            this.formYoungAge = document.querySelector(".jsMaxDoseYoungAge");
            this.formYoungDose = document.querySelector(".jsMaxDoseYoungDose");
            this.formYoung.style.display = "none";

            this.results = document.querySelector(".jsMaxDoseResults");

            this.events();
        }
    }

    events() {
        let self = this;

        this.type.addEventListener("change", function () {
            self.setTypeValue();
            self.calculate();
        });

        this.formFriedAge.addEventListener("input", function () {
            self.calculate();
        });

        this.formFriedDose.addEventListener("input", function () {
            self.calculate();
        });

        this.formCownlingAge.addEventListener("input", function () {
            self.calculate();
        });

        this.formCownlingDose.addEventListener("input", function () {
            self.calculate();
        });

        this.formClarkWeight.addEventListener("input", function () {
            self.calculate();
        });

        this.formClarkDose.addEventListener("input", function () {
            self.calculate();
        });

        this.formYoungAge.addEventListener("input", function () {
            self.calculate();
        });

        this.formYoungDose.addEventListener("input", function () {
            self.calculate();
        });
    }

    setTypeValue() {
        this.typeValue = this.type.value;

        switch (this.typeValue) {
            case "fried":
                this.formFried.style.display = "flex";
                this.formCownling.style.display = "none";
                this.formClark.style.display = "none";
                this.formYoung.style.display = "none";
                break;
            case "cowling":
                this.formFried.style.display = "none";
                this.formCownling.style.display = "flex";
                this.formClark.style.display = "none";
                this.formYoung.style.display = "none";
                break;
            case "clark":
                this.formFried.style.display = "none";
                this.formCownling.style.display = "none";
                this.formClark.style.display = "flex";
                this.formYoung.style.display = "none";
                break;
            case "young":
                this.formFried.style.display = "none";
                this.formCownling.style.display = "none";
                this.formClark.style.display = "none";
                this.formYoung.style.display = "flex";
                break;
            default:
                this.formFried.style.display = "none";
                this.formCownling.style.display = "none";
                this.formClark.style.display = "none";
                this.formYoung.style.display = "none";
        }
    }

    calculate() {
        let calculation = 0;

        if (this.validation()) {
            if(this.typeValue == 'fried' ) {
                calculation = (this.formFriedAge.value * this.formFriedDose.value) / 150;
            }

            if(this.typeValue == 'cowling' ) {
                calculation = (this.formCownlingAge.value * this.formCownlingDose.value) / 24;
            }

            if(this.typeValue == 'clark' ) {
                calculation = (this.formClarkWeight.value * this.formClarkDose.value) / 70;
            }

            if(this.typeValue == 'young' ) {
                calculation = (this.formYoungAge.value * this.formYoungDose.value) / (parseInt(this.formYoungAge.value) + 12);
            }

            this.results.innerHTML = calculation.toFixed(2);
        }
    }

    validation() {
        let validate = false;
        let resultsRow = document.querySelector(".form__row--results");
        resultsRow.style.display = "none";

        if(this.typeValue == 'fried' && this.formFriedAge.value.length > 0 && this.formFriedDose.value.length > 0) {
            validate = true;
        }

        if(this.typeValue == 'cowling' && this.formCownlingAge.value.length > 0 && this.formCownlingDose.value.length > 0) {
            validate = true;
        }

        if(this.typeValue == 'clark' && this.formClarkWeight.value.length > 0 && this.formClarkDose.value.length > 0) {
            validate = true;
        }

        if(this.typeValue == 'young' && this.formYoungAge.value.length > 0 && this.formYoungDose.value.length > 0) {
            validate = true;
        }

        if (validate) {
            resultsRow.style.display = "block";
        }

        return validate;
    }
}

export default MaxDose;