class VitaminD {
    constructor() {
        this.form = document.getElementById("jsCalcVitaminD");

        if (this.form) {
            this.vitaminProducer = document.querySelector(".jsVitaminDProducer");
            this.vitaminProducerValue = this.vitaminProducer.value;
            this.vitaminPrescription = document.querySelector(".jsVitaminDPrescription");
            this.vitaminPrescriptionValue = this.vitaminPrescription.value;
            this.vitaminQuantity = document.querySelector(".jsVitaminDQuantitySubstance");
            this.results = document.querySelector(".jsVitaminDResults");
            this.resultsDescription = document.querySelector(".jsVitaminDResultsDescription");

            this.setTypeValue();
            this.events();
        }
    }

    events() {
        let self = this;
        this.vitaminProducer.addEventListener("change", function () {
            self.setTypeValue();
            self.calculate();
        });

        this.vitaminPrescription.addEventListener("change", function () {
            self.setTypeValue();
            self.calculate();
        });

        this.vitaminQuantity.addEventListener("input", function () {
            self.calculate();
        });
    }

    setTypeValue() {
        this.vitaminProducerValue = parseFloat(this.vitaminProducer.value).toFixed(3);
        this.vitaminPrescriptionValue = this.vitaminPrescription.value;
    }

    calculate() {
        if (this.validation()) {
            let resultVit = 0;
            let resultVit2 = 0;
            let resultVitName = this.vitaminProducer.options[this.vitaminProducer.selectedIndex].text;

            if (this.vitaminPrescriptionValue == 'mcg') {
                resultVit = (( this.vitaminQuantity.value / this.vitaminProducer.options[this.vitaminProducer.selectedIndex].dataset.mcg ) * this.vitaminProducerValue).toFixed(2);
                resultVit2 = (( this.vitaminQuantity.value / this.vitaminProducer.options[this.vitaminProducer.selectedIndex].dataset.mcg ) * this.vitaminProducer.options[this.vitaminProducer.selectedIndex].dataset.gtt).toFixed(2);
                this.results.innerHTML = `W celu wykonania leku dodaj ${resultVit} g preparatu ${resultVitName} lub odlicz krople w ilości ${resultVit2}` ;
            } else if (this.vitaminPrescriptionValue == 'jm') {
                resultVit = (( this.vitaminQuantity.value / this.vitaminProducer.options[this.vitaminProducer.selectedIndex].dataset.jm ) * this.vitaminProducerValue).toFixed(2);
                resultVit2 = (( this.vitaminQuantity.value / this.vitaminProducer.options[this.vitaminProducer.selectedIndex].dataset.jm ) * this.vitaminProducer.options[this.vitaminProducer.selectedIndex].dataset.gtt).toFixed(2);
                this.results.innerHTML = `W celu wykonania leku dodaj ${resultVit} g preparatu ${resultVitName} lub odlicz krople w ilości ${resultVit2}` ;
            } else {
                resultVit = (( this.vitaminQuantity.value / this.vitaminProducer.options[this.vitaminProducer.selectedIndex].dataset.gtt ) * this.vitaminProducerValue).toFixed(2);
                this.results.innerHTML = `W celu wykonania leku dodaj ${resultVit} g preparatu ${resultVitName}`;
            }
            this.resultsDescription.innerHTML = `(Do obliczeń użyto uśrednionej gęstości preparatu ${resultVitName} – ${this.vitaminProducerValue} g/ml)`;
        }
    }

    validation() {
        let validate = false;
        let resultsRow = document.querySelector(".jsVitaminDResultsRow");
        resultsRow.style.display = "none";

        if (this.vitaminProducerValue > 0 && this.vitaminPrescriptionValue.length > 0 && this.vitaminQuantity.value.length > 0) {
            validate = true;
            resultsRow.style.display = "flex";
        }

        return validate;
    }
}

export default VitaminD;