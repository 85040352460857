class DoseParaIbu {
    constructor() {
        this.form = document.getElementById("jsCalcDoseParaIbu");

        if (this.form) {
            this.type = document.querySelector(".jsDoseParaIbuType");
            this.typeValue = this.type.value;
            this.weight = document.querySelector(".jsDoseParaIbuWeight");
            this.resultsParaRow = document.querySelector(".jsDoseParaRow");
            this.resultsPara = document.querySelector(".jsDoseParaResults");
            this.resultsPara1 = document.querySelector(".jsDoseParaResults1");
            this.resultsPara2 = document.querySelector(".jsDoseParaResults2");
            this.resultsPara3 = document.querySelector(".jsDoseParaResults3");
            this.resultsIbuRow = document.querySelector(".jsDoseIbuRow");
            this.resultsIbu = document.querySelector(".jsDoseIbuResults");
            this.resultsIbu1 = document.querySelector(".jsDoseIbuResults1");
            this.resultsIbu2 = document.querySelector(".jsDoseIbuResults2");

            this.events();
        }
    }

    events() {
        let self = this;
        this.type.addEventListener("change", function() {
            self.setTypeValue();
            self.calculate();
        });

        this.weight.addEventListener("input", function() {
            self.calculate();
        });
    }

    setTypeValue() {
        this.typeValue = this.type.value;
    }

    calculate() {
        let calculation = 0;
        let para = 15;
        let ibu = 10;

        if(this.validation()) {
            this.resultsPara.value = this.weight.value * para;
            this.resultsPara1.value = (this.resultsPara.value / 24).toFixed(2);
            this.resultsPara2.value = (this.resultsPara.value / 40).toFixed(2);
            this.resultsPara3.value = (this.resultsPara.value / 100).toFixed(2);
            this.resultsIbu.value = this.weight.value * ibu;
            this.resultsIbu1.value = (this.resultsIbu.value / 20).toFixed(2);
            this.resultsIbu2.value = (this.resultsIbu.value / 40).toFixed(2);
        }
    }

    validation() {
        let validate = false;
        this.resultsParaRow.style.display = "none";
        this.resultsIbuRow.style.display = "none";

        if (this.weight && this.weight.value > 0) {
            switch (this.typeValue) {
                case "all":
                    this.resultsParaRow.style.display = "block";
                    this.resultsIbuRow.style.display = "block";
                    validate = true;
                    break;
                case "paracetamol":
                    this.resultsParaRow.style.display = "block";
                    this.resultsIbuRow.style.display = "none";
                    validate = true;
                    break;
                case "ibuprofen":
                    this.resultsParaRow.style.display = "none";
                    this.resultsIbuRow.style.display = "block";
                    validate = true;
                    break;
                default:
                    this.resultsParaRow.style.display = "none";
                    this.resultsIbuRow.style.display = "none";
                    validate = false;
            }
        }

        return validate;
    }
}

export default DoseParaIbu;