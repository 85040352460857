class CocoaButter {
    constructor() {
        this.form = document.getElementById("jsCalcCocoaButter");

        if (this.form) {
            this.formSteps = document.querySelectorAll(".form__step");
            this.quantitySubstance = document.querySelector(".jsCalcCocoaQuantitySubstance");
            this.quantitySubstanceValue = this.quantitySubstance.value;
            this.quantityWanted = document.querySelector(".jsCalcCocoaQuantityWanted");
            this.weightButter = document.querySelector(".jsCalcCocoaButterSingle");
            this.currentStep = document.querySelector(".jsCalcStep");
            this.nextStep = document.querySelector(".jsCalcNextStep");
            this.prevStep = document.querySelector(".jsCalcPrevStep");
            this.substances = document.querySelectorAll(".jsCalcCocoaSubstance");
            this.calculatedSubstances = [];

            this.events();
        }
    }

    events() {
        let self = this;
        this.quantitySubstance.addEventListener("change", function () {
            self.setTypeValue();
            self.calculate();
        });

        this.prevStep.addEventListener("click", function () {
            const step = parseInt(self.currentStep.value) - 1;
            self.changeStep(step);
        });

        this.nextStep.addEventListener("click", function () {
            const step = parseInt(self.currentStep.value) + 1;
            self.changeStep(step);
        });

        this.quantityWanted.addEventListener("input", function () {
            self.calculate();
        });

        this.weightButter.addEventListener("input", function () {
            self.calculate();
        });
    }

    setTypeValue() {
        this.quantitySubstanceValue = this.quantitySubstance.value;
    }

    changeStep(step) {
        if((step < 1 || step > 3)) {
            return;
        }

        if(step > this.currentStep.value && this.validationStep()) {
            this.currentStep.value = step;
            this.showHideSteps();
            this.showHideSubstances();
            this.calculate();
        } else if(step < this.currentStep.value) {
            this.currentStep.value = step;
            this.showHideSteps();
            this.showHideSubstances();
            this.calculate();
        }
    }

    showHideSteps() {
        let self = this;
        this.formSteps.forEach((item) => {
            (item.dataset.step == this.currentStep.value) ? item.classList.add('active') : item.classList.remove('active');
        });
    }

    showHideSubstances() {
        this.substances.forEach((item) => {
            if (item.dataset.substance <= this.quantitySubstanceValue) {
                item.style.display = "flex";
                item.classList.add("active");
            } else {
                item.style.display = "none";
                item.classList.remove("active");
            }
        });
    }

    calculate() {
        if (this.currentStep.value == "3") {
            let resultsContainer = document.querySelector(".form__results");
            resultsContainer.innerHTML = "";
            let htmlResults = "";
            let calculationWeighSum = 0;
            let calculationResult = 0;
            this.calculatedSubstances.forEach((item, index) => {
                let calculation = item.gram * this.quantityWanted.value;
                htmlResults += `<p><strong>Substancji nr ${index + 1} (${item.substance_label}) należy odważyć:</strong></p>`;
                htmlResults += `<h3>${calculation.toFixed(2)} g</h3>`;
                calculationWeighSum += item.substance * calculation;
            });
            calculationResult = (this.weightButter.value * this.quantityWanted.value) - calculationWeighSum;
            htmlResults += `<p><strong>Do wykonania leku należy użyć następującej ilości masła kakaowego</strong></p>`;
            htmlResults += `<h3>${calculationResult.toFixed(2)} g</h3>`
            resultsContainer.innerHTML = htmlResults;
        }
    }

    validationStep() {
        let validate = false;

        switch (this.currentStep.value) {
            case "1":
                if(this.quantityWanted.value.length > 0 && this.weightButter.value.length > 0 && this.quantitySubstanceValue > 0) {
                    validate = true;
                }
                break;
            case "2":
                this.calculatedSubstances = [];
                this.substances.forEach((item) => {
                    if (item.classList.contains("active")) {
                        let substance = item.querySelector(".jsCalcCocoaSubstanceValue");
                        let substanceValue = substance.value;
                        let substanceGramValue = item.querySelector(".jsCalcCocoaSubstanceGramValue").value;

                        if(substanceValue > 0 && substanceGramValue.length > 0) {
                            this.calculatedSubstances.push({
                                "substance": parseFloat(substanceValue),
                                "substance_label": substance.options[substance.selectedIndex].text,
                                "gram": parseFloat(substanceGramValue),
                            });
                            validate = true;
                        } else {
                            validate = false;
                            return;
                        }
                    }
                });
                break;
            default:
        }

        return validate;
    }
}

export default CocoaButter;